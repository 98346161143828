import { Box, Button, Collapse, Fade, Grid, HStack, Heading, Input, Select, SimpleGrid, Slide, SlideFade, Stack, Text, filter, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'

import { CheckboxFilter } from '../components/Shop/FilterPanel/CheckboxFilter';
import Layout from '../components/Base/Layout'
import { MobileFilter } from '../components/Shop/MobileFilter'
import { PopoverIcon } from '../components/Header/PopoverIcon';
import { PriceRangePicker } from '../components/Shop/FilterPanel/PriceRangePicker';
import { SEO } from '../components/Base/Seo'
import { SingleProductCard } from '../components/Product/SingleProductCard';
import { fetchAllProducts } from '../components/hooks/queries';
import { useEffect } from 'react';

const Shop = () => {
  const products = fetchAllProducts()
  const [filtered, setFiltered] = useState([...products])

  // // PRICE FILTER
  // var maxPriceValue = Math.max(...products.map((o:any) => o.price.replace(/[^0-9.-]+/g, "")))
  // var minPriceValue = Math.min(...products.map((o: any) => o.price.replace(/[^0-9.-]+/g, "")))
  
  // const [minPrice, setMinPrice] = useState(minPriceValue.toString())
  // const [maxPrice, setMaxPrice] = useState(maxPriceValue.toString())
  
  const showFilteredResults = () => {

    if (filtered.length == 0) {
      return (
        products.map((item:any) => <SingleProductCard props={item}></SingleProductCard>)
      )
    } else {
        return (
          filtered.map((item:any) => <SingleProductCard props={item}></SingleProductCard>)
        )
    }
    
  }

  const handleFilters = (filters: any) => {

    var res = products.filter((d: any) => d.productCategories.cat.some((c: any) => filters.includes(c.id)))
    setFiltered(res)

    showFilteredResults()
      
  }

  //*SORT
    function onSelectionChange(e: any) {
    // check which select value is chosen
    const sortDirection = e.target.value 

    // making a shallow copy of an initial array of products
    const copyArray = [...filtered]; 

    //sorting copy array based on which value is chosen
    copyArray.sort((a:any, b:any) => {

      if (sortDirection == 'a-z') {
        return a.name.localeCompare(b.name)
      }

      if (sortDirection == "z-a") {
        return b.name.localeCompare(a.name)
      }

      if (sortDirection == 'priceDESC') {
        const aPrice = Number(a.price.replace(/[^0-9.-]+/g,""));
        const bPrice = Number(b.price.replace(/[^0-9.-]+/g,""));

        return aPrice - bPrice
      }

      if (sortDirection == 'priceASC') {
        const aPrice = Number(a.price.replace(/[^0-9.-]+/g,""));
        const bPrice = Number(b.price.replace(/[^0-9.-]+/g,""));

        return bPrice - aPrice
      }

    });
      
      if (filtered.length == 0) {
        products.sort((a:any, b:any) => {

      if (sortDirection == 'z-a') {
        return a.name.localeCompare(b.name)
      }

      if (sortDirection == "a-z") {
        return b.name.localeCompare(a.name)
      }

      if (sortDirection == 'priceDESC') {
        const aPrice = Number(a.price.replace(/[^0-9.-]+/g,""));
        const bPrice = Number(b.price.replace(/[^0-9.-]+/g,""));

        return aPrice - bPrice
      }

      if (sortDirection == 'priceASC') {
        const aPrice = Number(a.price.replace(/[^0-9.-]+/g,""));
        const bPrice = Number(b.price.replace(/[^0-9.-]+/g,""));

        return bPrice - aPrice
      }

        });
      setFiltered(products)

    }

    // re-render products
      setFiltered(copyArray)

    }
  
    // function changePrice() {
    // if (minPrice > maxPrice) {
    //   alert('wrong input')
    // } else {
    //   let list = filtered.filter((item:any) => Number(item.price.replace(/[^0-9.-]+/g, "")) >= Number(minPrice) && Number(item.price.replace(/[^0-9.-]+/g, "")) <= Number(maxPrice))
    //  setFiltered(list)
    // }
    // }
  
  // useEffect(() => {
  //   let result = filtered;
  //   result = handleFilters(result)
  // }, [handleFilters])

  const { isOpen, onToggle } = useDisclosure()

  const filteredProducts = () => {
    if (filtered.length == 0) {
      return <Text color="gray.500"> ({products.length} products)</Text>;
    } else if (filtered.length > 1) {
      return <Text color="gray.500"> ({filtered.length} products)</Text>;
    } else {
      return <Text color="gray.500"> ({filtered.length} product)</Text>;
    }
  };
  
    return (
      <Layout>
        <HStack alignItems="flex-start">
          {/* <Box height="100vh" minW="240px" boxShadow=" 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);"> */}
          <Stack display={{md: "flex", base: "none"}} height="100vh" minW="240px" position="sticky" top="0" pt="72px" px="8px" spacing="10" maxW="240px" boxShadow=" 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);">
              <CheckboxFilter
                handleFilters={(filters:any) => handleFilters(filters)}
                spacing="3"
                label="Category"
                checked={false}></CheckboxFilter>
      
              <Stack marginTop="16px !important">
                <Button onClick={onToggle} variant="ghost" size="lg"  p="0px" w="100%" justifyContent="space-between" fontWeight="700" rightIcon={<PopoverIcon isOpen={isOpen} />} _hover={{backgroundColor: "none"}}>
                  Price range
                </Button>
                  {/* <Collapse in={isOpen} animateOpacity>
                    <PriceRangePicker defaultValue={[minPriceValue, maxPriceValue]} />
                    <HStack spacing="6" onBlur={e => changePrice()}>
                      <Input type="number" onChange={event => setMinPrice(event.target.value)} placeholder={minPriceValue.toString()} defaultValue={minPriceValue.toString()} />
                      <Input type="number" onChange={event => setMaxPrice(event.target.value)} placeholder={maxPriceValue.toString()} defaultValue={maxPriceValue.toString()} />
                    </HStack>
                </Collapse> */}
              </Stack>
            </Stack>
          {/* </Box> */}

        <Box id="shopTemplatePart" height="100%" py="72px" width="100%" marginInline="1rem !important">
          <Stack
            spacing={{ base: '6', md: '4' }}
            direction={{ base: 'column', md: 'row' }}
            justify="space-between"
            align="flex-start"
            width="full"
            pb="28px"
          >
            <Stack direction={{ base: 'column', md: 'row' }} align="baseline">
              <Heading size="md" fontSize="2xl">
                Shop
                </Heading>
              {filteredProducts()}

              {/* <Text color="gray.500">({products.length} products)</Text> */}
            </Stack>
            <MobileFilter />
            <HStack display={{ base: 'none', md: 'flex' }}>
              <Text flexShrink={0} color="gray.500" fontSize="sm">
                Sort by
              </Text>
              {/* <SortbySelect /> */}
                  <Select size="sm" aria-label="Sort by" rounded="md" onChange={onSelectionChange}>
                    <option value="a-z">Name: A-Z</option>
                    <option value="z-a">Name: Z-A</option>
                    <option value="priceASC">Price: DESC</option>
                    <option value="priceDESC">Price: ASC</option>
                  </Select>
            </HStack>
          </Stack>
          <SimpleGrid columns={{base: 1, sm: 2, md: 3, lg: 4}} columnGap={4} rowGap={{ base: '8', md: '10' }} >            
            {showFilteredResults()}
          </SimpleGrid> 
        </Box>
      </HStack>
    </Layout>
  )
}

export default Shop

export const Head = () => (
  <SEO title="CDI Health" description="Shop page description" />
)